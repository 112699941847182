import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentAccessToken = this.authService.currentAccessTokenValue;
    const currentPermission = this.authService.currentPermissionValue;
    const currentIdOrg = this.authService.currentUserPermissionValue.IdOrganizacion
    if (currentAccessToken && currentPermission) {
      if (request.url.includes("apimarketplace")) {
        let idSuscripcion = this.authService.currentUserPermissionValue.IdEmpresa.toString();
        let idPortal = 'Ax8=';
        request = request.clone({
          headers: new HttpHeaders({
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${currentAccessToken}`,
            'IdSuscripcion': idSuscripcion,
            'IdPortal': idPortal
          })
        });
      } else {
        request = request.clone({
          headers: new HttpHeaders({
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${currentAccessToken}`,
            'X-Permission': currentPermission
          })
        });
      }
    }
    return next.handle(request);
  }
}
