import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService,
        private snackbar: MatSnackBar) { }
    private static accessTokenError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                if (!ErrorInterceptor.accessTokenError$.getValue()) {

                    ErrorInterceptor.accessTokenError$.next(true);

                    return this.authService.refreshToken().pipe(
                        switchMap((event: any) => {
                            ErrorInterceptor.accessTokenError$.next(false);
                            const currentAccessToken = this.authService.currentAccessTokenValue;
                            const currentPermission = this.authService.currentPermissionValue;
                            const newRequest = request.clone({
                                setHeaders: {
                                    'Content-Type': `application/json`,
                                    'Authorization': `Bearer ${currentAccessToken}`,
                                    'X-Permission': currentPermission
                                }
                            });
                            return next.handle(newRequest);
                        }),
                        catchError(er => {
                            this.authService.logout();
                            return throwError(er);
                        })
                    );
                }else {
                    const currentAccessToken = this.authService.currentAccessTokenValue;
                    const currentPermission = this.authService.currentPermissionValue;
                    return this.waitNewTokens().pipe(
                        switchMap((event: any) => {
                            const newRequest = request.clone({
                                setHeaders: {
                                    'Content-Type': `application/json`,
                                    'Authorization': `Bearer ${currentAccessToken}`,
                                    'X-Permission': currentPermission
                                }
                            });
                            return next.handle(newRequest);
                        })
                    );
                }
            } else if (err.status === 403) {
                this.authService.logout();
            }
            else if (err.status != 403 || err.status != 401) {
              console.log(err)
                this.snackbar.open(err.error.message, 'Error', {
                    duration: 4000
                });
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }

    // Wait until get the new access/refresh token
    private waitNewTokens(): Observable<any> {
        const subject = new Subject<any>();
        const waitToken$: Subscription = ErrorInterceptor.accessTokenError$.subscribe((error: boolean) => {
            if (!error) {
                subject.next();
                waitToken$.unsubscribe();
            }
        });
        return subject.asObservable();
    }
}
