import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionEnterprise, SessionSuscriptionInfo } from '../models/session-suscription-info.model';
import { SessionUserInfo } from '../models/session-user-info.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserPermission } from '../models/UserPermission.model';

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
    private cookieServices: CookieService,
    private jwtHelper: JwtHelperService) {
    
    this.setCurrentStyle();
  }


  saveAccessToken(accessToken: string) {
    const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
    const d = new Date(0);
    d.setUTCSeconds(jwtToken.exp);
    this.cookieServices.set('accessToken', accessToken, d, '/', environment.domainCookie, false, 'Lax');
  }

  savePermissionToken(tokenPermisos: string) {
    const jwtPermisosToken = JSON.parse(atob(tokenPermisos.split('.')[1]));
    const r = new Date(0);
    r.setUTCSeconds(jwtPermisosToken.exp);
    this.cookieServices.set('tokenPermisos', tokenPermisos, r, '/', environment.domainCookie, false, 'Lax');
  }

  public get currentUserValue(): SessionUserInfo {
    console.log(this.cookieServices.check('currentUser'));
    if (this.cookieServices.check('currentUser')) {
      let userIdToken = JSON.stringify(this.jwtHelper.decodeToken(this.currentUserTokenValue));
      let userObj = <any>JSON.parse(userIdToken);
      const user: SessionUserInfo = new SessionUserInfo();
      user.nombres = userObj.given_name;
      user.apellidos = userObj.family_name;
      user.email = userObj.email;
      console.log('userObj', userObj);
      return user;
    }
    else
      if (this.cookieServices.check('idToken')) {
        let userIdToken = JSON.stringify(this.jwtHelper.decodeToken(this.currentIdTokenValue));
        let userObj = <any>JSON.parse(userIdToken);
        const user: SessionUserInfo = new SessionUserInfo();
        user.nombres = userObj.given_name;
        user.apellidos = userObj.family_name;
        user.email = userObj.email;
        user.telefono = userObj.phone_number;
        // console.log('userObj', userObj);
        return user;
      }
    return new SessionUserInfo();
  }

  public get currentSuscriptionValue(): SessionSuscriptionInfo {
    if (this.cookieServices.check('tokenPermisos')) {
      let suscriptionTokenPermisos = JSON.stringify(this.jwtHelper.decodeToken(this.currentPermissionValue));
      let suscriptionObj = <any>JSON.parse(suscriptionTokenPermisos);
      let suscriptionSelected = <any>JSON.parse(atob(suscriptionObj.permisos));

      const suscription: SessionSuscriptionInfo = new SessionSuscriptionInfo();
      const enterprises: SessionEnterprise[] = [];

      suscription.id = suscriptionSelected.subscriptions.subscriptionid;
      suscription.nombre = suscriptionSelected.subscriptions.subscriptionname;
      suscription.administrador = suscriptionSelected.subscriptions.administrador;
      suscription.admincartera = suscriptionSelected.subscriptions.admincartera;
      suscription.tipoplanid = suscriptionSelected.subscriptions.tipoplanid;
      suscription.tipoplannombre = suscriptionSelected.subscriptions.tipoplannombre;
      suscriptionSelected.subscriptions.enterprises.forEach((item: any) => {
        let enterprise: SessionEnterprise = new SessionEnterprise();
        enterprise.codigo = item.codigo;
        enterprise.nombre = item.nombre;
        
        enterprises.push(enterprise);
      });

      suscription.empresas = enterprises;
      return suscription;
    }
    return new SessionSuscriptionInfo();
  }

  public get currentUserPermissionValue(): UserPermission {
    if (this.cookieServices.check('tokenPermisos')) {
      let suscriptionTokenPermisos = JSON.stringify(this.jwtHelper.decodeToken(this.currentPermissionValue));
      let suscriptionObj = <any>JSON.parse(suscriptionTokenPermisos);
      let enterpriseSelected = <any>JSON.parse(atob(suscriptionObj.permisos));

      const userPermission: UserPermission = new UserPermission();

      userPermission.IdEmpresa = enterpriseSelected.IdEmpresa;
      userPermission.IdOrganizacion = enterpriseSelected.IdOrganizacion;
      userPermission.IdUsuario = enterpriseSelected.IdUsuario;
      userPermission.Pi = enterpriseSelected.Pi;
      userPermission.Ruts = enterpriseSelected.Ruts;
      userPermission.AdminCartera = enterpriseSelected.AdminCartera;

      return userPermission;

    }
    return new UserPermission();
  }

  public get currentPermissionValue(): string {
    return this.cookieServices.get('tokenPermisos');
  }

  public get currentAccessTokenValue(): string {
    return this.cookieServices.get('accessToken');
  }

  public get currentRefreshTokenValue(): string {
    return this.cookieServices.get('refreshToken');
  }

  //este se debe ir
  public get currentIdTokenValue(): string {
    return this.cookieServices.get('idToken');
  }

  public get currentUserTokenValue(): string {
    return this.cookieServices.get('currentUser');
  }

  public get currentStyle(): string {
    return this.cookieServices.get('currentStyle');
  }


  refreshToken(): Observable<void> {
    return this.http.get<any>(`${environment.urlApiSSO}api/v1/autenticacion/refrescartoken?token=${this.currentRefreshTokenValue}`)
      .pipe(map(data => {
        if (data) {
          this.saveAccessToken(data.accessToken);
        } else {
          this.logout();
        }
      }
      ),
        catchError(this.handleError));
  }

  logout() {
    this.cookieServices.delete('userData', '/', environment.domainCookie);
    this.cookieServices.delete('currentSuscription', '/', environment.domainCookie);
    this.cookieServices.delete('currentUser', '/', environment.domainCookie);
    this.cookieServices.delete('accessToken', '/', environment.domainCookie);
    this.cookieServices.delete('tokenPermisos', '/', environment.domainCookie);
    this.cookieServices.delete('refreshToken', '/', environment.domainCookie);
    this.cookieServices.delete('idToken', '/', environment.domainCookie);
    this.cookieServices.delete('currentUser', '/', environment.domainCookie);
    window.open(`${environment.urlLogout}`, '_self');
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }


setCurrentStyle(){
  let days = 30;
  const r = new Date();
  r.setUTCSeconds(days * 24 * 60 * 60);

  if(this.cookieServices.check('currentStyle')){
    this.cookieServices.delete('currentStyle');
  }
  this.cookieServices.set('currentStyle', 'ic-light', r, '/', environment.domainCookie, true, 'Lax');
}


}