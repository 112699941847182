import { SessionSuscriptionInfo } from "./session-suscription-info.model";

export class SessionUserInfo {
    codigorespuesta: boolean;
    id: number;
    email: string;
    codigo: string;
    nombres: string;
    origenid: number;
    fechacreacion: Date;
    telefono: string;
    apellidos: string;
    accesstoken: string;
    idtoken: string;
    refreshtoken: string;
    tokenpermisos: string;
    session: string;
    invitadocotizar: boolean;
    redirect: string;
    segment: string;
    userpersona: string;
    suscripcion: SessionSuscriptionInfo;
}

export class EmpresasRelacionadas {
    imagenUrl: string;
  }

export class Portales {
    id: string;
    value: string;
    host: string;
    useThisPortal?: boolean = false;
    codigo?: string;
    imagen?: string;
    politica?: string;
    primaryColor?: string;
    logo?: string;
    slogan?: string;
    background?: string;
    empresasRelacionadas?: Array<EmpresasRelacionadas>
  }
  