export const environment = {
    production: false,
    urlApiEmpresa: 'https://qa-api-consulta-emp.azurewebsites.net/',
    urlApiSSO: 'https://qa-api-login.iconstruye.cl/',
    urlApiGeneral: 'https://qa-api-general-nmp.azurewebsites.net/',
    urlApiNmpCore: 'https://dev-apim-ic.azure-api.net/nmp-sso/',
    domainCookie: 'iconstruye.cl',
    urlLogout: 'https://marketplacessoqa.iconstruye.cl/public/',
    urlMetabase: 'https://prod-api-metabase.azurewebsites.net/',
    instrumentationKey: '2d762043-d274-474b-be04-6b9e5c49e455',
    urlApiConsulta: 'https://qa-api-consulta-emp.azurewebsites.net/',
    urlApiConsultaOc: 'https://qa-api-consulta-oc.azurewebsites.net/',
    urlApiConsultaPurchase: 'https://qa-api-consulta-com.azurewebsites.net/',
    urlApiComando: 'https://qa-api-comando-dt-scm.azurewebsites.net/',
    urlApiComprador: 'https://apimarketplace-des.iconstruye.cl:10443/',
    urlApiConfiguracion :'https://qa-api-gateway.iconstruye.cl/configuracion/',
    urlApiCompra :'https://qa-api-gateway.iconstruye.cl/compra/',
    urlApiImpresion :'https://qa-api-gateway.iconstruye.cl/impresion/'
};
