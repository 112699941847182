export class SessionSuscriptionInfo {
    id: string;
    nombre: string;
    tipoplanid?: number;
    tipoplannombre?: string;
    administrador: boolean;
    admincartera: boolean;
    empresas: SessionEnterprise[];
}

export class SessionEnterprise {
    codigo: string;
    id: number;
    nombre: string;
    activo: boolean;
    fechacreacion: Date;
    porcentajecompletitud: number;
}
